<template>
  <a :href="link">
    <li :class="['mx-8', 'menu_item', { small: className, large: !className }]">
      {{ text }}
      <v-icon v-if="icon" dark size="21" class="px-0">{{ icon }}</v-icon>
      <ul v-if="items.length">
        <!-- features -->
        <li class="dropdown_item" v-for="(item, i) in items" :key="i">
          <a :href="item.route" class="dropdown-link">{{ item.title }} </a>
        </li>
      </ul>
    </li>
  </a>
</template>
<script>
export default {
  props: {
    text: {},
    icon: {},
    size: {},
    link: {},
    className: {},
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
<style scoped>
.menu_item {
  font-family: "Montserrat-regular";
  font-size: 14px !important;
  text-transform: uppercase;
  color: var(--color-white);
  -webkit-transition: all 0.15s linear 0s;
  transition: all 0.15s linear 0s;
  transition-property: all;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  transition-delay: 0s;
}
.menu_item:hover,
.menu_item:hover + i {
  color: #cdcdcd;
}
.large {
  padding: 24px 0 24px 0 !important;
}
@media (max-width: 992px) {
  .sf-menu > li .mega-menu,
  .sf-menu li > ul {
    margin-top: -2px;
    text-align: left;
    list-style: none;
    padding: 15px 0;
    background-color: transparent;
    box-shadow: 0px 2px 3px rgb(0 0 0 / 10%);
    min-width: 16.5em;
  }
  .sf-menu li.sfHover > ul {
    display: block;
  }
  .sf-menu,
  .sf-menu ul {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

/* dropdown menu */
.dropdown-menu {
  box-shadow: none;
  border-radius: 0;
  border-color: #303030;
  z-index: 1001;
  font-size: 16px;
  margin-top: 0;
}
.dropdown_item {
  font-size: 14px !important;
}
[class*="right"] .dropdown-menu {
  right: 0;
  left: auto;
}

.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover {
  color: #303030;
  background-color: transparent;
}

[class*="_0"] [class*="col-"],
header [class*="col-"] {
  margin-top: 0;
  margin-bottom: 0;
  min-height: 0;
}

.page_header {
  -webkit-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  top: 0;
  left: 0;
  right: 0;
  position: relative;
  z-index: 1000;
}

.page_header.transparent_header {
  margin-bottom: -106px;
  padding-right: 20px;
  padding-left: 20px;
}

@media (min-width: 1400px) {
  .page_header.transparent_header {
    padding-right: 60px;
    padding-left: 60px;
  }
}

@media (min-width: 1550px) {
  .page_header.transparent_header {
    padding-right: 120px;
    padding-left: 120px;
  }
}

.page_header.transparent_header.affix {
  margin-bottom: -66px;
  background-color: rgba(255, 255, 255, 0.8);
}

@media (max-width: 991px) {
  .page_header.transparent_header {
    margin-bottom: -66px;
  }

  .page_header.transparent_header [class*="container"] {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.page_header.section_padding_10 [class*="container"] {
  -webkit-transition: padding 0.2s ease-in-out 0s;
  transition: padding 0.2s ease-in-out 0s;
}

@media (min-width: 992px) {
  .page_header.section_padding_10 [class*="container"] {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .page_header.section_padding_10.affix [class*="container"] {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.page_header.mobile-active {
  z-index: 1002;
}
.h-inherit {
  height: inherit;
}

/*
**main menu (min 992px) styles
*/
.sf-menu a {
  font-family: "montserrat";
  text-transform: uppercase;
  font-style: normal;
  font-weight: 300;
}

@media (min-width: 992px) {
  /* menu layout */
  .sf-menu,
  .sf-menu ul {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .transparent_header .sf-menu,
  .transparent_header .sf-menu ul {
    display: inline-block;
  }

  .transparent_header .sf-menu:before {
    content: "";
    display: block;
    position: absolute;
    left: 4px;
    right: 4px;
    height: 38px;
    top: 18px;
    width: 0;
    background: -webkit-linear-gradient(left, #89b1b9, transparent);
    background: linear-gradient(to right, #89b1b9, transparent);
    -webkit-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s;
    -webkit-transition-duration: 0.6s;
    transition-duration: 0.6s;
  }
}

@media (min-width: 992px) and (min-width: 1200px) {
  .transparent_header .sf-menu:before {
    left: 13px;
    right: 13px;
  }
}

@media (min-width: 992px) and (min-width: 1400px) {
  .transparent_header .sf-menu:before {
    left: 23px;
    right: 23px;
  }
}

@media (min-width: 992px) {
  .transparent_header .sf-menu:hover:before {
    width: 100%;
  }

  .sf-menu ul {
    border: 1px solid #303030;
  }

  .sf-menu li {
    position: relative;
  }

  .sf-menu li > ul {
    position: absolute;
    display: none;
    top: 100%;
    z-index: 99;
  }

  .sf-menu li:hover > ul,
  .sf-menu li.sfHover > ul {
    display: block;
  }

  .sf-menu a {
    display: block;
    position: relative;
  }

  .sf-menu li > ul > li > ul {
    top: -16px;
    left: 100%;
  }
  /* menu skin */
  .sf-menu a {
    text-decoration: none;
    zoom: 1;
    /* IE7 */
    -webkit-transition-property: color, background-color, border-color;
    transition-property: color, background-color, border-color;
  }
  .sf-menu .dropdown-link {
    padding: 7px 40px 7px 0;
  }
  .sf-menu li {
    white-space: nowrap;
    /* no need for Supersubs plugin */
    *white-space: normal;
    /* ...unless you support IE7 (let it wrap) */
    -webkit-transition: background 0.2s;
    transition: background 0.2s;
  }

  .sf-menu ul li {
    padding: 0 30px;
  }

  .sf-menu .mega-menu > ul > li li {
    padding: 0;
  }

  .sf-menu > li {
    display: inline-block;
    position: relative;
    margin: 0 -2px;
  }

  .sf-menu > li > a {
    color: #303030;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .page_copyright.ds .sf-menu > li > a {
    font-weight: 300;
    color: #616161;
  }

  .page_copyright.ds .sf-menu > li > a:hover {
    color: #95c6d8;
    opacity: 1;
  }

  .sf-menu > li > a:after {
    content: "";
    position: absolute;
    bottom: 16px;
    left: 16px;
    right: 17px;
    height: 1px;
    background-color: #303030;
    opacity: 0;
  }
}

@media (min-width: 992px) and (min-width: 1200px) {
  .sf-menu > li > a:after {
    left: 23px;
    right: 24px;
  }
}

@media (min-width: 992px) and (min-width: 1400px) {
  .sf-menu > li > a:after {
    left: 31px;
    right: 32px;
  }
}

@media (min-width: 992px) and (min-width: 1650px) {
  .sf-menu > li > a:after {
    left: 36px;
    right: 37px;
  }
}

@media (min-width: 992px) {
  .sf-menu > li.active > a:after {
    opacity: 1;
  }

  .sf-menu > li > a:hover,
  .sf-menu > li > a:hover + i {
    opacity: 0.6;
  }

  .bordered_items .sf-menu {
    text-align: center;
  }

  .bordered_items .sf-menu > li {
    padding: 0;
  }

  .header_white .sf-menu > li > a {
    color: #303030;
  }

  .header_darkgrey .sf-menu > li > a {
    color: #ffffff;
  }
  .header_black .sf-menu > li > a {
    color: #ffffff;
  }
  .header_black .sf-menu > li > i {
    color: #ffffff;
  }
  .language-btn {
    display: inline-flex !important;
    align-items: baseline;
  }
  .header_gradient .sf-menu > li > a {
    color: #ffffff;
  }

  .header_gradient .sf-menu > li > a:hover,
  .header_gradient .sf-menu > li.active > a {
    color: #303030;
  }

  .sf-menu li:hover,
  .sf-menu li.sfHover {
    /* only transition out, not in */
    -webkit-transition: none;
    transition: none;
  }
  /* second and next levels */
  .sf-menu > li .mega-menu,
  .sf-menu li > ul {
    margin-top: -2px;
    text-align: left;
    list-style: none;
    padding: 15px 0;
    background-color: #ffffff;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
    min-width: 16.5em;
    /* allow long menu items to determine submenu width */
  }

  .sf-menu ul a {
    color: #616161;
    position: relative;
    -webkit-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s;
  }

  .sf-menu ul a:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 0;
    height: 30px;
    width: 0;
    -webkit-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s;
  }

  .sf-menu .mega-menu ul {
    box-shadow: none;
    padding: 0;
    border: none;
  }

  .sf-menu ul a:hover,
  .sf-menu ul li.active > a {
    color: var(--color-black);
    padding: 7px 20px 7px 20px;
  }

  .sf-menu ul a:hover:before,
  .sf-menu ul li.active > a:before {
    width: 1px !important;
    background-color: var(--color-black);
  }
  /* third level */
  .sf-menu ul ul {
    margin-top: 0;
  }
  /*** arrows (for all except IE7) **/
  /*top menu level*/
  /* styling for right-facing arrows */
  .sf-arrows ul .sf-with-ul:after {
    position: absolute;
    right: 0;
    top: 7px;
    content: "\f067";
    font-family: FontAwesome;
    font-size: 8px;
  }
}

/* common paddings */
@media (min-width: 992px) {
  .sf-menu > li > a {
    padding-left: 2px;
    padding-right: 0px;
  }

  .bordered_items .sf-menu > li > a {
    padding-right: 40px;
    padding-left: 44px;
  }
}

/* -md- paddings */
@media (min-width: 992px) and (max-width: 1199px) {
  .sf-menu > li > a {
    padding-left: 16px;
    padding-right: 17px;
  }

  .bordered_items .sf-menu > li > a {
    padding-right: 20px;
    padding-left: 24px;
  }
}

/* -lg- paddings */
@media (min-width: 1200px) {
  .sf-menu > li > a {
    padding-left: 23px;
    padding-right: 24px;
  }

  .boxed .col-md-6 .sf-menu > li > a {
    padding-left: 14px;
    padding-right: 14px;
  }
}

@media (min-width: 1400px) {
  .sf-menu > li > a {
    padding-left: 31px;
    padding-right: 32px;
  }
}

@media (min-width: 1650px) {
  .sf-menu > li > a {
    padding-left: 36px;
    padding-right: 37px;
  }
}

.page_copyright .sf-menu > li:last-child > a {
  padding-right: 0 !important;
}

/*
**main menu mobile styles
*/
@media (max-width: 991px) {
  .mainmenu_wrapper {
    /*position: relative;*/
    position: fixed;
    visibility: hidden;
    z-index: 10;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: transparent;
    opacity: 0;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
  }

  .mobile-active .mainmenu_wrapper {
    visibility: visible;
    opacity: 1;
    background-color: #111;
    background-color: rgba(0, 0, 0, 0.9);
  }

  .nav-justified > li > a {
    text-align: left;
  }

  .sf-menu {
    position: fixed;
    overflow: auto;
    background-color: #303030;
    padding: 10px 20px 10px 10px;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 100%;
    font-size: 1.1em;
    text-transform: uppercase;
    font-weight: 400;
    word-wrap: break-word;
    text-align: left;
    -webkit-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
    opacity: 0;
  }

  .mobile-active .sf-menu {
    opacity: 1;
    right: 20px;
  }

  .sf-menu > li > a,
  .sf-menu a {
    color: #ffffff;
  }

  .sf-menu ul {
    list-style: none;
    padding-left: 10px;
  }

  .sf-menu ul li a {
    display: block;
    padding: 10px;
    font-weight: 300;
    font-size: 16px;
    text-transform: none;
  }

  .sf-menu ul li a:before {
    content: "-";
    padding-right: 5px;
  }

  .sf-menu ul ul li a:before {
    content: "--";
    padding-right: 5px;
  }
  /*** arrows (for all except IE7) **/
  .sf-arrows .sf-with-ul {
    padding-right: 2.5em;
    *padding-right: 1em;
    /* no CSS arrows for IE7 (lack pseudo-elements) */
  }
  /* styling for both css and generated arrows */
  .sf-arrows .sf-with-ul:after {
    font-family: "FontAwesome";
    content: "\f107";
    position: absolute;
    right: 1.44em;
    height: 0;
    width: 0;
    font-size: 20px;
  }
}

@media (min-width: 992px) {
  .sf-menu .md-hidden {
    display: none;
  }
}

@media (max-width: 991px) {
  #more-li {
    display: none;
  }
}

.sf-menu i[class] {
  padding-right: 7px;
  font-size: 1.1em;
}

/* Mega Menu */
@media screen and (min-width: 992px) {
  .mainmenu_wrapper ul .mega-menu ul {
    min-width: auto;
  }

  .mainmenu_wrapper .menu-item-has-mega-menu {
    position: relative;
  }

  .mainmenu_wrapper .mega-menu {
    display: none;
    position: absolute;
    width: 940px;
    top: 100%;
    z-index: 10;
  }

  .mainmenu_wrapper .menu-item-has-mega-menu:hover .mega-menu {
    display: block;
  }
  /* row */
  .mainmenu_wrapper .mega-menu-row {
    position: static;
    display: table;
    table-layout: fixed;
  }

  .mainmenu_wrapper .mega-menu-row ul {
    position: static;
    display: block;
  }
  /* column */
  .mainmenu_wrapper .mega-menu-col {
    display: table-cell;
    width: 2000px;
    padding: 0 20px;
    border-left: 1px solid rgba(48, 48, 48, 0.5);
    /* overrides from settings / styling / mega-menu */
  }

  .mainmenu_wrapper .mega-menu-col > a {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 900;
  }

  .mainmenu_wrapper .mega-menu-col:first-child {
    border-left: none;
  }

  .mainmenu_wrapper ul ul .mega-menu {
    width: auto;
    top: -20px;
    margin-left: 1px;
    display: block;
    width: auto;
  }

  .mainmenu_wrapper ul ul .mega-menu li {
    display: block;
    width: auto;
    border: none;
  }
}

@media screen and (min-width: 1200px) {
  .mainmenu_wrapper .mega-menu {
    width: 1140px;
  }

  .mainmenu_wrapper ul .mega-menu ul {
    min-width: 12em;
  }
}

@media screen and (max-width: 991px) {
  .mainmenu_wrapper .mega-menu {
    position: static;
  }
}
</style>
