<template>
  <header
    class="d-flex justify-space-between align-center social__black wa__menu bordered_items page_header"
  >
    <v-container class="header__container">
      <div id="mySidenav" class="sidenav" :class="['sidenav', { full: mobileRes }]">
        <div :class="['close_nav', { 'd-none': !mobileRes }]" @click="mobileRes = false">
          <v-icon dark>WMi-cancel-linear</v-icon>
        </div>
        <nav class="mainmenu_wrapper h-inherit">
          <ul
            class="d-block text-left pl-0 mb-0 list-unstyled mainmenu nav sf-menu h-inherit"
          >
            <v-list class="nav__list">
              <v-list-group
                v-for="item in menuItems"
                :key="item.title"
                v-model="item.active"
                :append-icon="item.icon"
                class="nav__list__group"
                no-action
              >
                <template v-slot:activator>
                  <v-list-item-content class="nav__list__item__content">
                    <a :href="item.route" class="text-decoration-none">
                      <v-list-item-title
                        class="nav__list__item"
                        v-text="item.title"
                      ></v-list-item-title>
                    </a>
                  </v-list-item-content>
                </template>

                <v-list-item
                  v-for="child in item.items"
                  :key="child.title"
                  dark
                  class="nav__list__item__children"
                >
                  <a :href="child.route" class="text-decoration-none">
                    <v-list-item-content class="pa-0">
                      <v-list-item-subtitle
                        class="nav__list__item__children--child"
                        v-text="child.title"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </a>
                </v-list-item>
              </v-list-group>
            </v-list>
            <li class="align-items-center language-sm">
              <a class="change-language lili-link" @click="$_changeLocalePage()"
                >{{ $_t("localeName") }} Version</a
              >
            </li>
          </ul>
          <div class="copyrights-sm">
            <div class="social-item">
              <i class="rt-icon2-phone-outline highlight fontsize_24"></i>
              03-6362-3355
            </div>

            <div class="social-item">
              <i class="rt-icon2-mail highlight fontsize_24"></i>
              info@lilianamodels.com
            </div>
          </div>
        </nav>
      </div>
      <div class="row h-inherit" style="align-items: baseline">
        <div class="col-sm-12 col-md-2 col-xs-6 col-lg-2 pt-4">
          <BrandName
            :white="true"
            class="site_logo text-center text-md-left float-md-none"
          />
          <span
            @click="mobileRes = true"
            :class="['toggle_menu', { 'd-none': mobileRes }]"
          >
            <span></span>
          </span>
        </div>

        <div class="col-lg-8 col-md-9 d-none d-md-block py-0 h-100 text-center">
          <nav class="mainmenu_wrapper h-inherit">
            <ul
              class="d-flex justify-center pl-0 mb-0 list-unstyled align-center mainmenu nav sf-menu h-inherit"
            >
              <MenuItem
                v-for="(item, i) in navItems"
                :key="i"
                :text="item.title"
                :items="item.items"
                :link="item.route"
                :icon="item.icon"
              />
            </ul>
          </nav>
        </div>
        <div class="col-lg-2 col-md-1 text-right d-none d-md-flex justify-end">
          <ul class="inline-dropdown d-flex ma-0 pa-0">
            <div class="functional-dropdown flex-right">
              <li v-if="user" class="dropdown login-dropdown">
                <router-link
                  class="header-button user-container ml-1 d-flex text-decoration-none"
                  :to="{
                    name: 'dashboard',
                    params: { locale: this.$_getlocaleParam() },
                  }"
                  aria-haspopup="true"
                  role="button"
                  aria-expanded="false"
                >
                  <div class="auth-info" v-if="user">
                    <div class="user-name wa__f__m__r">
                      {{ $_changeModelProperty(user, "first_name") }}
                    </div>
                    <div class="user-type wa__f__m__r">{{ user.type }}</div>
                  </div>
                  <i class="WMi-user-linear"></i>
                </router-link>
              </li>
              <li v-else>
                <router-link
                  :to="{
                    name: 'login',
                    params: { locale: this.$_getlocaleParam() },
                  }"
                  class="text-decoration-none header-button"
                >
                  <i class="WMi-user-linear"></i>
                </router-link>
              </li>
              <li class="dropdown language-dropdown">
                <a
                  class="change-language lili-link text-decoration-none"
                  @click="$_changeLocalePage()"
                  >{{ $_t("localeName") }}</a
                >
              </li>
            </div>
          </ul>
        </div>
      </div>
    </v-container>
  </header>
</template>
<script>
import { UserStorage } from "@/utils/storage";
import MenuItem from "../../Section/MenuItem.vue";
import BrandName from "../BrandName.vue";
export default {
  components: {
    MenuItem,
    BrandName,
  },
  data: () => ({
    mobileRes: null,
    user: UserStorage.get(),
  }),
  computed: {
    // for responsive munu
    menuItems() {
      return [
        {
          icon: "",
          title: this.$_tc("menu", 1),
          route: this.localeUrl(this.$baseUrl),
        },
        {
          icon: "mdi-chevron-down",
          title: this.$_tc("menu", 2),
          route: 'javascript:;',
          items: [
            {
              title: this.$_tc("menuAboutDropdown", 1),
              route: this.localeUrl(this.$baseUrl + `/about-us`),
            },
            {
              title: this.$_tc("menuAboutDropdown", 2),
              route: this.localeUrl(this.$baseUrl + `/company-profile`),
            }
          ],
        },
        {
          icon: "mdi-chevron-down",
          active: true,
          title: this.$_tc("menu", 3),
          route: this.localeUrl(this.$baseUrl + `/models`),
          items: [
            {
              title: this.$_tc("menuDropdown", 1),
              route: this.localeUrl(this.$baseUrl + `/models`),
            },
            {
              title: this.$_tc("menuDropdown", 2),
              route: this.localeUrl(this.$baseUrl + `/models/women`),
            },
            {
              title: this.$_tc("menuDropdown", 3),
              route: this.localeUrl(this.$baseUrl + `/models/men`),
            },
            {
              title: this.$_tc("menuDropdown", 4),
              route: this.localeUrl(this.$baseUrl + `/models/kids-babies`),
            },
          ],
        },
        {
          icon: "",
          title: this.$_tc("menu", 4),
          route: this.localeUrl(this.$baseUrl + `/talents`),
        },
        {
          icon: "",
          title: this.$_tc("menu", 5),
          route: this.localeUrl(this.$baseUrl + `/actors`),
        },
        {
          icon: "",
          title: this.$_tc("menu", 6),
          route: this.localeUrl(this.$baseUrl + `/apply`),
        },
        {
          icon: "",
          title: this.$_tc("menu", 7),
          route: this.localeUrl(this.$baseUrl + `/contact`),
        },
        {
          icon: "",
          title: this.user
            ? `${this.$_changeModelProperty(this.user, "first_name")} - dashboard`
            : "login",
          route: this.user
            ? this.localeUrl(this.$baseUrl)
            : this.localeUrl(this.$baseUrl + `/login`),
        },
      ];
    },
    // for navigation
    navItems() {
      return [
        {
          title: this.$_tc("menu", 1),
          route: this.localeUrl(this.$baseUrl),
        },
        {
          icon: "mdi-chevron-down",
          title: this.$_tc("menu", 2),
          route: 'javascript:;',
          items: [
            {
              title: this.$_tc("menuAboutDropdown", 1),
              route: this.localeUrl(this.$baseUrl + `/about-us`),
            },
            {
              title: this.$_tc("menuAboutDropdown", 2),
              route: this.localeUrl(this.$baseUrl + `/company-profile`),
            }
          ],
        },
        {
          icon: "mdi-chevron-down",
          title: this.$_tc("menu", 3),
          route: this.localeUrl(this.$baseUrl + `/models`),
          items: [
            {
              title: this.$_tc("menuDropdown", 1),
              route: this.localeUrl(this.$baseUrl + `/models`),
            },
            {
              title: this.$_tc("menuDropdown", 2),
              route: this.localeUrl(this.$baseUrl + `/models/women`),
            },
            {
              title: this.$_tc("menuDropdown", 3),
              route: this.localeUrl(this.$baseUrl + `/models/men`),
            },
            {
              title: this.$_tc("menuDropdown", 4),
              route: this.localeUrl(this.$baseUrl + `/models/kids-babies`),
            },
          ],
        },
        {
          title: this.$_tc("menu", 4),
          route: this.localeUrl(this.$baseUrl + `/talents`),
        },
        {
          title: this.$_tc("menu", 5),
          route: this.localeUrl(this.$baseUrl + `/actors`),
        },
        {
          title: this.$_tc("menu", 6),
          route: this.localeUrl(this.$baseUrl + `/apply`),
        },
        {
          title: this.$_tc("menu", 7),
          route: this.localeUrl(this.$baseUrl + `/contact`),
        },
      ];
    },
  },
};
</script>
<style>
.social__black {
  background-color: var(--color-black) !important;
  height: 68px;
}
@media screen and (min-width: 600px) and (max-width: 960px) {
  .header__container {
    max-width: 960px !important;
  }
}
@media (min-width: 992px) {
  .header__container {
    max-width: 1340px !important;
  }
}
/* side nav */
.nav__list.v-list {
  background-color: #303030 !important;
}
.nav__list__group .v-list-group__header .v-list-item__icon {
  justify-content: start !important;
}
.nav__list__group .v-list-group__header .v-list-item__icon i {
  color: var(--color-white) !important;
}
.v-list-item__title.nav__list__item {
  font-family: "montserrat-light";
  color: var(--color-white) !important;
  font-weight: 300;
  letter-spacing: 5px;
  font-size: 1.1em;
}
.nav__list__item__content {
  flex: none;
}
.nav__list__item__content .v-list-item {
  min-height: 20px;
}
.nav__list .v-list-item {
  color: var(--color-white) !important;
}
.nav__list__item__children {
  padding-left: 30px !important;
  min-height: 26px;
}
.nav__list__item__children--child {
  font-family: "montserrat-light";
  text-transform: uppercase;
  padding: 0 8px !important;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 5px;
  color: #959191 !important;
}
.sidenav {
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: #303030;
  overflow-x: hidden;
  transition: 0.5s;
  text-align: center;
}
.full {
  width: 100%;
}
.close_nav {
  position: fixed;
  top: 13px;
  right: 13px;
  padding: 5px;
  z-index: 10000;
  background: rgb(48, 48, 48);
}
.toggle_menu {
  position: absolute;
  top: 50%;
  right: 50%;
  margin-top: -30px;
  margin-right: -30px;
  display: none;
  width: 60px;
  height: 60px;
  cursor: pointer;
  z-index: 1001;
  visibility: visible;
}

.header_white .toggle_menu span,
.header_white .toggle_menu span:before,
.header_white .toggle_menu span:after,
.transparent_header .toggle_menu span,
.transparent_header .toggle_menu span:before,
.transparent_header .toggle_menu span:after {
  background-color: #303030;
}

.page_toplogo .toggle_menu {
  position: fixed;
  top: 0;
  left: auto;
  right: 0;
  background-color: #303030;
  margin: 0;
}

.toggle_menu:hover span:before {
  top: -7px;
}

.toggle_menu:hover span:after {
  top: 7px;
}

.toggle_menu span,
.toggle_menu span:before,
.toggle_menu span:after {
  display: block;
  width: 24px;
  height: 1px;
  position: absolute;
  background-color: #ffffff;
  content: "";
  left: 0;
  -webkit-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}

.toggle_menu span {
  left: 18px;
  top: 50%;
  margin-top: -1px;
}

.toggle_menu span:before {
  top: -6px;
}

.toggle_menu span:after {
  top: 6px;
}

.toggle_menu.mobile-active {
  background-color: #303030;
  border: none;
  left: auto;
  top: 0;
  right: 0;
  position: fixed;
  margin: 0;
  z-index: 3000;
}

.toggle_menu.mobile-active:after {
  position: absolute;
  top: 14px;
  left: 10px;
  font-family: "rt-icons-2";
  content: "\e117";
  font-size: 40px;
  color: #ffffff;
}
/* dropdown  */

.dropdown-menu {
  box-shadow: none;
  border-radius: 0;
  border-color: #303030;
  z-index: 1001;
  font-size: 16px;
  margin-top: 0;
}

[class*="right"] .dropdown-menu {
  right: 0;
  left: auto;
}

.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover {
  color: #303030;
  background-color: transparent;
}

.toggle_menu.mobile-active span {
  display: none;
}

.login-dropdown .dropdown-menu {
  min-width: 360px;
  padding: 30px 30px;
}
.language-dropdown .dropdown-menu {
  min-width: 65px;
  left: 20px !important;
  top: 9px !important;
  text-align: center;
  margin-top: 12px;
}
.language-dropdown .dropdown-menu ul {
  padding-left: 0;
  list-style-type: none;
}
.language-dropdown .dropdown-menu ul li {
  font-family: "cizel";
  cursor: pointer;
}
.language-dropdown .dropdown-menu ul li:hover {
  opacity: 0.6;
}
/* other */
.change-language {
  font-size: 18px;
  color: var(--color-white) !important;
  padding-left: 15px;
  border-left: 0.1px solid #eeeeee;
  text-decoration: none !important;
  font-family: "montserrat-regular";
  font-weight: 300;
}
.lili-link:hover {
  color: var(--color-purple);
}
.flex-right {
  display: flex !important;
  justify-content: right;
  align-items: center;
}
.page_header a.header-button {
  position: relative;
  display: inline-block;
  /* color: #303030; */
  color: #fff;
  padding: 11px;
  font-size: 18px;
  line-height: 1;
}
.user-container .auth-info {
  text-align: right;
  margin-right: 5px;
}
.user-container .user-name {
  font-size: 15px;
  text-transform: uppercase;
}
.user-container .user-type {
  font-size: 9px;
  background-color: #686868;
  padding: 1px 5px;
  text-align: center;
}
/* media query */
@media (min-width: 1200px) {
  .container {
    max-width: 1340px !important;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1440px;
  }
}
@media (max-width: 960px) {
  .toggle_menu {
    left: 0;
    margin-right: 0;
    display: block;
  }
  .mainmenu {
    padding-top: 100px;
  }
  .copyrights-sm {
    font-family: "montserrat-light";
    font-size: 1.1em;
    position: absolute;
    bottom: 0;
    display: block;
    margin: 0 auto;
    width: 100%;
    background-color: #000;
    padding: 10px 0;
  }
  .copyrights-sm .social-item {
    color: #fff;
    text-transform: uppercase;
  }
  .copyrights-sm .social-item i {
    padding-right: 7px;
    font-size: 1.1em;
  }
  .mainmenu.nav li.language-sm {
    display: block;
    text-align: center !important;
    padding-top: 20px;
    text-transform: uppercase !important;
    font-family: "montserrat-light";
  }
  .mainmenu.nav li {
    padding: 10px 10px;
  }
  .change-language {
    border-left: none;
    border-top: 1px solid #6e6e6e;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: rgb(110, 110, 110);
    padding-left: 5px;
    padding-top: 10px;
    letter-spacing: 5px;
  }
}
</style>
