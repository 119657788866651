<template>
  <v-container>
    <v-row>
      <v-col>
        <div class="text-uppercase social__divider--title py-0">
          {{ title }}
        </div>
        <template v-if="route">
          <v-divider class="mx-1 mt-2 mt-md-1"></v-divider>
          <ul
            class="bread list-unstyled d-flex width-full pl-0 justify-content-center text-uppercase mt-4 mt-md-7"
          >
            <li>
              <a :href="localeUrl(`${$baseUrl}`)">{{ $_tc("route", 1) }}</a>
            </li>
            <li class="active">{{ $_tc("route", 2) }}</li>
          </ul>
        </template>
        <template v-if="!route && subTitle">
          <v-divider class="mx-1 mt-2 mt-md-1"></v-divider>
          <div class="text-uppercase social__divider--sub-title py-0">
            {{ subTitle }}
          </div>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  props: {
    title: {},
    route: {
      default: false,
    },
    dividerColor: {},
    subTitle: {},
  },
};
</script>
<style scoped>
.v-divider {
  border-color: #dedede !important;
}
@media screen and (min-width: 1340px) {
  .container {
    max-width: 1340px !important;
  }
}
@media (max-width: 600px) {
  .social__divider--title {
    font-size: 38px !important;
    letter-spacing: 1px !important;
  }
}
.bread li a,
.bread li {
  font-family: "montserrat-regular";
  color: #616161;
  font-size: 14px;
}
.bread > li + li:before {
  display: inline-block;
  vertical-align: middle;
  color: inherit;
  padding: 0;
  margin: -2px 10px 0;
  content: "";
  width: 3px;
  height: 3px;
  background-color: #616161;
  border-radius: 50%;
}
.social__divider--sub-title {
  font-family: "montserrat-light";
  color: #878787;
  font-size: 20px;
  text-align: center;
}
.social__divider--title {
  font-family: "Barlow-EL" !important;
  font-size: 50px;
  letter-spacing: 10px;
  text-align: center !important;
}
</style>
